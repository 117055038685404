<template>
  <div class="ytm-landing-background">
    <div class="ellipse22 ytm-background-ellipse-size"/>
    <div class="ellipse21 ytm-background-ellipse-size"/>
    <svg v-if="screen_width > 500" class="star23" viewBox="0 0 413 991" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M-444.454 49.9508C-326.325 -33.9324 -162.976 -9.14598 -75.0488 106.004L-55.856 131.139C-6.20561 196.161 75.7502 227.795 156.211 212.995C297.696 186.971 424.977 303.102 411.996 446.374L405.348 519.749C399.913 731.476 251.52 912.573 45.0088 959.593L-19.9128 978.864C-171.368 1023.82 -329.158 930.837 -363.212 776.565C-377.616 711.313 -415.515 653.639 -469.694 614.524L-485.196 603.332C-604.918 516.897 -650.617 360.175 -596.115 222.938L-594.743 219.482C-572.067 162.384 -533.344 113.072 -483.253 77.5017L-444.454 49.9508Z" fill="url(#paint0_linear_75_175)"/>
      <defs>
        <linearGradient id="paint0_linear_75_175" x1="-247.5" y1="34.5" x2="47.9589" y2="970.855" gradientUnits="userSpaceOnUse">
          <stop stop-color="#9747FF"/>
          <stop offset="1" stop-color="#D55EFF"/>
        </linearGradient>
      </defs>
    </svg>
  </div>
</template>

<script>
import '../Background';
import {inject} from "vue";

export default {
  name: 'LoginBackground',
  setup() {
    let screen_width = inject('screen_width');
    return {screen_width};
  },
};
</script>

<style scoped>
.star23 {
  position: absolute;
  width: 60vw;
  height: 57vw;
  left: -21vw;
  top: 8vw;
}
</style>