<template>
  <div style="position: relative; width: 100vw; min-height: 100vh; overflow: hidden">
    <LoginBackground/>
    <div class="ytm-landing-wrapper">
      <LandingTop @scrollToFooter="$router.push('/')"/>
      <div style="position: relative; width: 100%; min-height: 90vh; margin-top: 1vh">
        <div class="ytm-logreg-form-wrapper">
          <div class="ytm-logreg-form">
            <p class="ytm-landing-font ytm-logreg-title">Вход</p>
            <input
                class="ytm-logreg-input"
                v-model="form_data.username"
                @input="error_message = ''"
                placeholder="Имя пользователя"
                type="text"
                @keyup.enter="submitForm"
                autofocus
            >
            <input
                class="ytm-logreg-input"
                v-model="form_data.password"
                @input="error_message = ''"
                placeholder="Пароль"
                type="password"
                @keyup.enter="submitForm"
                style="margin-bottom: 4%"
            >
            <p v-if="error_message" class="ytm-landing-font ytm-logreg-error-text">{{error_message}}</p>
            <button class="ytm-logreg-button" style="margin: 0 auto max(1.5vw, 1.5vh);" @click="submitForm">
                <span class="ytm-landing-font ytm-logreg-button-text" style="margin-right: 1vw">
                  Войти
                </span>
              <svg style="width: max(1.77vw, 0.9rem)" viewBox="0 0 34 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 13.3679C0.895431 13.3679 8.19842e-08 14.2634 0 15.3679C-8.19842e-08 16.4725 0.89543 17.3679 2 17.3679L2 13.3679ZM33.1296 16.7821C33.9106 16.0011 33.9106 14.7348 33.1296 13.9537L20.4016 1.22579C19.6206 0.444737 18.3543 0.444737 17.5732 1.22579C16.7922 2.00683 16.7922 3.27316 17.5732 4.05421L28.8869 15.3679L17.5732 26.6816C16.7922 27.4627 16.7922 28.729 17.5732 29.5101C18.3543 30.2911 19.6206 30.2911 20.4016 29.5101L33.1296 16.7821ZM2 17.3679L31.7153 17.3679L31.7153 13.3679L2 13.3679L2 17.3679Z" fill="white"/>
              </svg>
            </button>
            <p class="ytm-landing-font ytm-or-google" style="margin-bottom: max(1.5vh, 1.5vw)">
              или
            </p>
            <div style="width: 100%; margin-bottom: max(1.25vw, 1.25vh)">
              <button @click="openGoogle" class="ytm-google-login">
                <span class="ytm-landing-font ytm-google-login-span" style="padding-left: max(2.5vw, 1.5rem)">
                  <img src="https://img.icons8.com/color/48/000000/google-logo.png"/>
                  Продолжить с Google
                </span>
              </button>
            </div>
            <p class="ytm-landing-font ytm-logreg-plain-text" style="margin-top: 1.75vw; padding-bottom: 2.5vw">
              У вас ещё нет профиля?<br>
              <a class="ytm-logreg-link" href="/register" @click.prevent="openRegister" style="cursor: pointer">Давайте исправим!</a>
            </p>
          </div>
        </div>
        <div class="ytm-logreg-image"/>
        <svg class="star22" viewBox="0 0 849 819" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M174.546 49.9508C292.675 -33.9324 456.024 -9.14598 543.951 106.004L563.144 131.139C612.794 196.161 694.75 227.795 775.211 212.995C916.696 186.971 1043.98 303.102 1031 446.374L1024.35 519.749C1018.91 731.476 870.52 912.573 664.009 959.593L599.087 978.864C447.632 1023.82 289.842 930.837 255.788 776.565C241.384 711.313 203.485 653.639 149.306 614.524L133.804 603.332C14.0815 516.897 -31.617 360.175 22.8851 222.938L24.2575 219.482C46.9334 162.384 85.6555 113.072 135.747 77.5017L174.546 49.9508Z" fill="url(#paint0_linear_75_172)"/>
          <defs>
            <linearGradient id="paint0_linear_75_172" x1="371.5" y1="34.5" x2="666.959" y2="970.855" gradientUnits="userSpaceOnUse">
              <stop stop-color="#9747FF"/>
              <stop offset="1" stop-color="#D55EFF"/>
            </linearGradient>
          </defs>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import LandingTop from '../Navbar';
import LoginBackground from "./Background";
import "../LandingPage";
import {getGoogleUrl} from "@/util/get-google-url";

export default {
  name: 'NewLoginPage',
  components: {LoginBackground, LandingTop},
  data() {
    return {
      form_data: {
        username: '',
        password: '',
      },
      error_message: '',
    };
  },
  methods: {
    openGoogle() {
      window.open(getGoogleUrl('/', this.$route.query.to || '/home'), '_self');
    },
    openRegister() {
      this.$router.push({path: '/register', query: this.$route.query});
    },
    submitForm() {
      if (!this.form_data.username || !this.form_data.password) {
        return;
      }
      this.$store.dispatch('auth/login', {
        user_data: this.form_data,
        to: this.$route.query.to || '/home',
      }).then(response => {
        if (!response.success) {
          this.error_message = 'Неверный логин или пароль';
          this.form_data.password = '';
        }
      });
    },
  },
};
</script>

<style scoped>
.star22 {
  position: absolute;
  width: 60vw;
  right: -12.5vw;
  bottom: 0;
  z-index: -100;
}

@media only screen and (max-width: 750px) {
  .star22 {
    bottom: -20%;
    width: 70vw;
  }
}

@media only screen and (max-width: 600px) {
  .star22 {
    right: -5vw;
  }
}

@media only screen and (max-width: 500px) {
  .star22 {
    width: 90vw;
  }
}

@media only screen and (max-width: 325px) {
  .star22 {
    bottom: 0;
  }
}
</style>